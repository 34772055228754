* { box-sizing: border-box; }

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate2d(0, 0, 0);
    transform: translate2d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate2d(-400%, 0, 0);
    transform: translate2d(-400%, 0, 0);
  }
}

@keyframes ticker {
    
  0% {
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-400%, 0, 0);
    transform: translate3d(-400%, 0, 0);
  }
}

.ticker-wrap {
  
  position: fixed;
  bottom: 200;
  width: 90%;
  overflow: hidden;
  height: auto;
  background-color: rgba(#000, 0.9); 
  padding-left: 90%;
  box-sizing: content-box;

  

}
.ticker {
    display: flex;
    height: 7rem;
    line-height: 6rem;  
    white-space: nowrap;
    padding-right: 100%;
    flex-direction: row;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite; 
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
   -webkit-animation-name: ticker;
           animation-name: ticker;
    -webkit-animation-duration: 100s;
            animation-duration: 100s;

    

  }
  .ticker__item {
    display: inline-block;
    orientation: horizontal;
    width: 100;
    padding: 0 2rem;
    font-size: 2rem;
    color: white;   

  }

