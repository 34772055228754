:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}
.btn--disabled{
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}
.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--outline2 {
    background-color: transparent;
    color: rgb(0, 0, 0);
    padding: 8px 20px;
    border: 1px solid black;
    transition: all 0.3s ease-out;
}
.btn--outline3 {
    background-color: transparent;
    color: rgb(255, 255, 255);
    padding: 8px 20px;
    border: 1px solid black;
    background-color: black;
    transition: all 0.3s ease-out;
}
.btn--outlinered {
    background-color: rgba(145, 0, 0, 0.9);
    color: rgba(255, 255, 255, 0.842);
    padding: 8px 20px;
    border: 1px solid rgba(145, 0, 0, 0.9);
    transition: all 0.3s ease-out;
}
.btn--outlinered:hover {
    background: rgb(0, 0, 0);
    color: #940000;
    transition: all 0.3s ease-out;
    transition: 250ms;
}

.btn--medium {
padding: 8px 20px;
font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}
.btn--large2 {
    padding: 12px 26px;
    font-size: 30px;
}
.btn--language {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(255, 255, 255, 0.842);
    padding: 6px 4px;
    border: 0px solid rgba(26, 26, 26, 0.767);
    transition: all 0.3s ease-out;
}
.btn--languageactive {
    background-color: rgba(53, 53, 53, 0.212);
    color: rgba(255, 255, 255, 0.842);
    padding: 6px 4px;
    border: 0px solid rgba(255, 255, 255, 0.767);
    transition: all 0.3s ease-out;
}
.btn--medium:hover, .btn--large:hover, .btn--outline3:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.btn--language:hover,.btn--languageactive:hover {
    background: rgba(71, 71, 71, 0.733);
    color: #dadada;
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.btn--outline2:hover {
    background: rgb(0, 0, 0);
    color: #ffffff;
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.error {
    color: "red";
    border:2px solid red;
  }