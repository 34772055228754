
    .container1 {
        min-width: "48%";
        width:"auto";
        margin-top: "10vh";
        display: "flex";
        flex-direction: "column";
        flex-wrap: "wrap";
        text-align: 'center';
      }
      .container2 {
        min-width: "48%";
        width:"auto";
        margin-top: "10vh";
        display: "flex";
        flex-direction: "column";
         flex-wrap: "wrap";
        text-align: 'center';
      }
      
      .imagecontainer {
          width: 500;
          height: 600;
          margin-bottom: 15;
          margin-top: 15;
          flex:1;
      }
      .rowcontainer2 {
          width: "100%";
          text-align: 'center';
          flex-direction: "row";
          justify-content: 'center';
          flex-wrap: "wrap";
          flex:1;
      }
      
      .rowcontainer3 {
          margin-left: "15%";
          margin-right: "15%";
          margin-top: "15%";
          text-align: 'center';
          flex-direction: "row";
          justify-content: 'center';
          display: "flex";
          flex-wrap: "wrap";
          flex:1;
      }
      .centertext {
          text-align: 'center';
          flex-direction: "column";
          margin-top: "20px";
          font-size: "2rem";
          justify-content: 'center';
      }
      .centertextdesc {
          text-align: 'center';
          flex-direction: "column";
          margin-top: 2;
          justify-content: 'center';
      }
         .lefttext {
       text-align: 'left';
       flex-direction: "column";
       margin-top: 5;
       justify-content: 'flex-start';
       font-family: "Inter"
       }
        