
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&family=Josefin+Sans&family=Ms+Madi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300&display=swap');

body{
    background: #ebebeb;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f3f3f3, #f3f3f3);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #e7e7e7, #f0f0f0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.topbody{
    margin-top: 10px;
    text-align: center;
    /* height: 600px; */
}
.form{
    background-color: white;
    border-radius: 5px;
    width: max(80%,400px);
    margin: 20px auto;
    padding: 20px;
    text-align: left;
    /* height: 600px; */
}
.form2{
    background-color: rgba(182, 182, 182, 0.055);
    width: 95%;
    margin: 10px auto;
    padding: 10px;
    text-align: left;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    /* height: 600px; */
}
.ordersforms{
    flex-flow: row;
    display: flex;
    /* height: 600px; */
}
.statusbarorders{
    background-color: rgba(56, 56, 56, 0.103);
    border-radius: 0px;
    text-align: left;
    flex: 2;
    /* height: 600px; */
}
.infobarorders{
    border-radius: 0px;
    text-align: left;
    flex: 0.2;
    /* height: 600px; */
}
.form-body{
    text-align: left;
    padding: 20px 10px;
    display: flex;
    flex-flow: column;
}
.form-body3{
    text-align: left;
    padding: 4px 1px;
    display: flex;
    margin-left: 15px;
    flex-flow: column;
}
.form-body2{
    text-align: left;
    padding: 20px 10px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
}

.form-body4{
    text-align: left;
    padding: 20px 10px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
}
.form-body > *{
    padding: 2px;
}
.form-body3 > *{
    padding: 2px;
}
.form__label{
    width: 50%;
}
.form__titellabel{
    font-size: 24px;
}
.form__smalltext{
    font-size: 10px;
}
.form__logotext{
    font-size: max(6vw, 50px);
    font-family: 'Josefin Sans', sans-serif;
}

.form__logotextsmall{
    font-size: min(48px, 6vw);
    font-family: 'Josefin Sans', sans-serif;
}
.form__logotextslogan{
    font-size: 3rem;
    font-family: 'Oooh Baby', sans-serif;
}
.form__logotextslogan2{
    flex: 1;
    flex-direction: space-between;
    font-size: max(1vw, 20px);
    margin-top: -8px;
    font-family: 'Oxygen', sans-serif;
    border-bottom-width: 100;
    color: rgb(41, 41, 41);
}
.form__text1small{
    font-size: 1.2rem;
    font-family: 'Josefin Sans', sans-serif;
}
.form__bigtext{
    font-size: 100px;
}
.form__medtext{
    font-size: 20px;
}
.form__styletext{
    font-size: 20px;
    font-family: "Ms Madi";
}
.form__shemeofworktext {
    font-size: max(40px,3vw);
    font-family: "Inter";
    font-style: "bold",
}
.form__shemeofworktextsmaller {
    font-size: min(20px,3vw);
    text-align: left;
    font-family: "Inter";
    font-style: "bold",
}
.form__text4 {
        font-size: min(2rem,4vw);
        color: rgb(34, 34, 34);
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
          'Lucida Sans', Arial, sans-serif;
}
.form__text5 {
    font-size: min(1.6rem,3vw);
    color: rgb(34, 34, 34);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.form__text6 {
    font-size: min(1.2rem,2.4vw);
    color: rgb(59, 59, 59);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.form__liststyletext{
    color: #000000bb;
    font-size: min(24px, 6vw);
    text-align: 'left';
    flex-direction: "column";
    padding-top: 10;
    margin-top: 10px;
    justify-content: 'flex-start';
    font-family: 'PT Sans', sans-serif;
}
.btn--disabled{
    background-color: transparent;
    color: rgb(0, 0, 0);
    padding: 8px 20px;
    border: 1px solid black;
    transition: all 0.3s ease-out;
    font-size: 20px;
}
.btn--disabled[disabled]{
    background-color: transparent;
    color: rgb(138, 138, 138);
    padding: 8px 20px;
    border: 1px solid rgb(153, 153, 153);
    transition: all 0.3s ease-out;
}
.btn--disabled:hover {
    background: rgb(0, 0, 0);
    color: #ffffff;
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.btn--outlinered {
    background-color: rgba(145, 0, 0, 0.9);
    color: rgba(255, 255, 255, 0.842);
    padding: 8px 20px;
    border: 1px solid rgba(145, 0, 0, 0.9);
    transition: all 0.3s ease-out;
}
.btn--outlinered:hover {
    background: rgb(0, 0, 0);
    color: #940000;
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.form__input{
    font-size: 1.1rem;
    width: 100%;
    height: 40px;
    padding: 10px;
}
::placeholder { /* Most modern browsers support this now. */
    color:    rgba(0, 0, 0, 0.315);
 }
.form__halfinput{
    font-size: 1.1rem;
    height: 40px;
    min-width: 180px;
    margin-right: 10px;
    padding: 10px;
}
.form__input:hover, .form__halfinput:hover{
    border: 2px solid black;
    border-radius: 10px;
    transition: all 0.3s ease-out;
}
.form2:hover{
    border: 2px solid rgb(59, 59, 59);
    border-radius: 10px;
}
.footer{
    text-align: center;
}
.footer2{
    text-align: right;
}
.error {
    color: "red";
    border:2px solid red;
  }