video {
  object-fit: cover;
    width: 50%;
    height: 55vh;
    min-width: 300px;
    z-index: 1;
    object-fit: contain;
    display: flex;
    transition: all 0.2s ease-out;
    flex:1,
      
  }
  video:hover {
    padding : 3px;
    transition: 200ms;
   
    }
  .image1 {
      object-fit: cover;
        height: 25vh;
        object-fit: contain;
        display: flex;
        width: 40vw;
        flex:1,
          
  }
  .hero-containertop {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100%;
    width:100%;
    display: flex;
    flex: 1;
    margin-top: -0px;
    padding: 5px 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    flex-wrap: wrap;
    box-shadow: inset 0 0 0 1000px rgba(238, 238, 238, 0.637);
    transition: all 0.3s ease-out;
    overflow:hidden; 
  }
  .hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 600px;
    width:50%;
    min-width: 300px;
    padding-left: 20px;padding-right: 5px;
    max-width: 800px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1,
  }
  .hero-container > h1 {
    color: rgb(37, 37, 37);
    
    font-size: min(100px,6vw);
    margin-top: 50px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: rgb(34, 34, 34);
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }

  .sliding-background{
    margin-top: 20px;
  }
