@import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&family=Josefin+Sans&family=Ms+Madi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300&display=swap');
.navbar {
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
    height: 80px;
    display: flex;
    justify-content: right;
    align-items: right;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: right;
    height: 80px;
    max-width: 1700px;
    margin-left:100px;
  }
  .navbar-logo {
    color: rgb(0, 0, 0);
    justify-self: start;
    margin-left: 10px;
    margin-top: 10px;
    width: 18vw;
    min-width: 12vw;
    cursor: pointer;
    flex-direction: column;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 3.2rem;
    font-family: 'Josefin Sans', sans-serif;
  }
    
  .navbar-logowhite {
    color: rgb(255, 255, 255);
    justify-self: start;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 3.2rem;
    font-family: 'Josefin Sans', sans-serif;
  }
  .navbar-logo-img {
    
    margin-left: 2px;
    margin-top: -12px;
    width: 12vw;
    min-width: 120px;
    cursor: pointer;
    align-items: top;
  }
  .navbar-logo-img-large {
    
    margin-left: 2px;
    margin-top: 2px;
    width: 24vw;
    min-width: 240px;
    cursor: pointer;
    align-items: center;
  }
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  .typo3yazilim {
    flex: 1;
    flex-direction: space-between;
    font-size: 1.05rem;
    margin-top: -8px;
    font-family: 'Oxygen', sans-serif;
    border-bottom-width: 100;
    color: rgb(41, 41, 41);
  }
  .typo3yazilimv2 {
    flex: 1;
    flex-direction: space-between;
    font-size: 1.82rem;
    margin-top: -8px;
    font-family: 'Oxygen', sans-serif;
    border-bottom-width: 100;
    color: rgb(41, 41, 41);
  }
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 2px;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  .nav-linksactive {
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 4px solid rgb(0, 0, 0);
  }
  .nav-links:hover,.nav-linksactive:hover {
    border-bottom: 4px solid rgb(0, 0, 0);
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: rgb(0, 0, 0);
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #cccccc;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
    .nav-linksactive {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
    .nav-links:hover,.nav-linksactive:hover {
      background-color: rgb(27, 27, 27);
      color: #f0f0f0;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: rgb(22, 22, 22);
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: rgb(22, 22, 22);
      padding: 14px 20px;
      border: 1px solid rgb(20, 20, 20);
      transition: all 0.3s ease-out;
    }
    .nav-links-active-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: rgb(22, 22, 22);
      padding: 14px 20px;
      border: 4px solid rgb(20, 20, 20);
      transition: all 0.3s ease-out;
    }
    .nav-links-mobile:hover,.nav-links-active-mobile:hover {
      background: rgb(15, 15, 15);
      color: #ececec;
      transition: 250ms;
    }
  }