.cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    margin: 0 0;
  }
  
  .cards__wrapper {
    position: relative;
    margin-left: min(5%,100px);
    margin-right: min(5%,100px);
    flex-wrap: wrap;
  }
  
  .cards__items {
    margin-bottom: 8px;
    margin-left: 8px;
    flex-wrap: wrap;
  }
  
  .cards__item {
    padding:5px;
    display: flex;
    flex: 1;
    margin: 0 0rem;
    border-radius: 0px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(177, 177, 177, 0.295);
    -webkit-filter: drop-shadow(0 6px 20px rgba(252, 252, 252, 0.017));
    filter: drop-shadow(0 6px 20px rgba(255, 255, 255, 0.017));
    border-radius: 5px;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    object-fit:contain;
    max-width: 100%;
    max-height: 100%;
    padding-top: 80px;
    
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    object-fit:contain;
    content: attr(data-category);
    position: relative;
    bottom: 0;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.795);
    object-fit: center;
    text-align: center;
    background-color: #bdbdbd00;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    object-fit:contain;
    position: absolute;
    top: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    bottom: 0;
    display: block;
    resize: contain;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: center;
    text-align: center;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 6px 6px 6px;
    height: 24px;
  }
  
  .cards__item__text {
    color: #000000d5;
    font-size: 14px;
    line-height: 12px;

  }
  
  @media only screen and (min-width: 100px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 100px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }